import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ipadIcon from "../images/edit.png";
import webIcon from "../images/large_web.png";
import phoneIcon from "../images/checkout.png";

import rbiiIcon from "../images/tailwind-rbii-jacobs.png";


function AboutPage() {
  return (
    <Layout>
      <SEO
        title="Restaurants"
        keywords={[`Jacob's`, `apps`, `web`, `order`, `design`, `mobile`, `testimony`, `restaurants`, `admin`]}
      />

      <section className="flex flex-col md:flex-col items-center">

        <h2 className="bg-red-800 text-xl text-white font-bold inline-block p-2">
          Jacob's Restaurant
        </h2>

        <div style={{ height: 5 }} />

        <p className="leading-loose text-xl inline-block p-2 text-center">
          {" "} <a
            href="https://jacobsrestaurantsonoma.com/"
            className="font-bold underline text-gray-900"
          >
            Web
            </a>
          , {" "}
          <a
            href="https://apps.apple.com/us/app/jacobs-restaurant/id1461508900?ls=1"
            className="font-bold underline text-gray-900"
          >
            iOS
            </a>
          , and {" "}
          <a
            href="https://play.google.com/store/apps/details?id=com.rbii.glock"
            className="font-bold underline text-gray-900"
          >
            Android
            </a>
        </p>

        <p className="leading-loose inline-block p-2 text-center">
          Jacob was looking for a way to get away from being a 'pizza' place. His new {" "} <a
            href="https://jacobsrestaurantsonoma.com/"
            className="font-bold underline text-gray-900"
          > website</a>, has a menu that features heartier entrees. Search engines crawl this way too, googlers are able to find entree images and descriptions.
        </p>

        <p className="leading-loose inline-block p-2 text-center">
          Talking on the phone is prone to mistakes, miscommunication, and misorders. Instead Jacob reads orders from an iPad in realtime. Customers read the menu directly from the admin app, menu changes occur instantly.
        </p>

        <p className="leading-loose inline-block p-2 text-center">
          While Jacob is in the kitchen, there has to be a way for customers to have their orders taken simultaneously. From within or outside of the restaurants customers can queue orders from their {" "} <a
            href="https://apps.apple.com/us/app/jacobs-restaurant/id1461508900?ls=1"
            className="font-bold underline text-gray-900"
          >mobile</a> devices.
        </p>

        <div style={{ height: 25 }} />

        <img src={webIcon} alt="Jacob's website" />

        <div style={{ height: 5 }} />

        <section className="flex flex-col md:flex-row items-center">
          <div className="w-2/5">
            <img src={phoneIcon} alt="Jacob's app" />
          </div>
          <figure className="w-2/3">
            <img src={ipadIcon} alt="Jacob's Admin App" />
          </figure>
        </section>

        <div style={{ height: 15 }} />

        {/* <div style={{height:20}}/> */}

        <img src={rbiiIcon} alt="Jacob's website and apps" />


        <div style={{ height: 20 }} />

        <p className="text-xl font-bold">
          Features
        </p>
        <ul>
          <li className="text-center my-1  p-2 ">
            Jacob's own
            {" "} <a
              href="https://apps.apple.com/us/app/jacobs-restaurant/id1461508900?ls=1"
              className="font-bold underline text-gray-900"
            >
              iOS
            </a> and {" "} <a
              href="https://play.google.com/store/apps/details?id=com.rbii.glock"
              className="font-bold underline text-gray-900"
            >
              Android
            </a> apps that integrate with Square business. Mobile payments are sent through customers' devices using Google and Apple pay.
          </li>
          <li className="text-center my-1 p-2">
            At any point in the day, Jacob may update his menu or close the store. His store can be updated through his iPad or phone; the changes are seen in the appstores immediately.
          </li>
          <li className="text-center my-1 p-2">
            The new website is a state of the art {" "} <a
              href="https://developers.google.com/web/progressive-web-apps/"
              className="font-bold underline text-gray-900"
            >
              progressive web app
            </a>.
          </li>
        </ul>

      </section>
    </Layout>
  );
}

export default AboutPage;
